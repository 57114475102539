import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useParams, useHistory } from 'react-router-dom';
import hoc from '../../../hoc/PageContainer';
import styles from './Order.module.scss';
import server from '../../../utils/axios/api';
import billingo from '../../../utils/axios/billingo';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/hu_HU';
import zeroFill from 'zero-fill';
import SignaturePad from 'react-signature-canvas';

import PageGuard from '../../../components/PageGuard/PageGuard';

import OrderLoader from '../../../components/OrderLoader/OrderLoader';
import OrderLines from '../../../components/Orders/OrderLines/OrderLines';
import OrderTotal from '../../../components/Orders/OrderTotal/OrderTotal';
import { PageHeader, Button, Row, Col, Card, Input, Form, Select, Modal, DatePicker, Typography, Tag, Dropdown, Menu, Space } from 'antd';
import { ExclamationCircleOutlined, PercentageOutlined, DownOutlined } from '@ant-design/icons';
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text, Paragraph } = Typography;

const pagePath = 'order';

const Order = (props) => {
	const [pageDetails, setPageDetails] = useState({});

	const [order, setOrder] = useState({ customer: {}, user: {}, products: [{ orderLine: {} }], orderStatus: {} });
	const [orderForm] = Form.useForm();
	const [requiredMark, setRequiredMarkType] = useState('required');
	const [listUser, setListUser] = useState([
		{ id: 1, name: 'teszt1' },
		{ id: 2, name: 'teszt2' },
	]);
	const [listCustomer, setListCustomer] = useState([]);
	const [listOrderStatus, setListOrderStatus] = useState([]);
	const [listOrderWebshopStatus, setListOrderWebshopStatus] = useState([]);
	const [buttonType, setButtonType] = useState({
		kpSzamla: 'default',
		szallitolevel: 'default',
		utalasosSzamla: 'default',
	});
	const [loading, setLoading] = useState({
		save: false,
		billingoInvoice: false,
		billingoStorno: false,
		orderQuote: false,
		downloadInvoice: false,
	});
	const [orderLoader, setOrderLoader] = useState(false);
	const [alertModal, setAlertModal] = useState(false);

	const sigCanvas = useRef({});

	let params = useParams();
	let history = useHistory();

	const menu = (
		<div style={{ backgroundColor: '#000066', padding: '10px', borderRadius: '7px', display: 'flex', flexFlow: 'row wrap' }}>
			<Button block onClick={() => handleInvoiceDownloadButtonClick(60)} style={{ marginBottom: '16px' }}>
				Alapértelmezett
			</Button>
			<Button block onClick={() => handleInvoiceDownloadButtonClick(120)}>
				Másodlagos (nyomtatási hiba esetén)
			</Button>
		</div>
	);

	useEffect(() => {
		props.setLoading(true);
	}, [params.id]);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		getOrder();
	}, [params.id]);

	useEffect(() => {
		server
			.get('/list/user/include')
			.then((response) => {
				let filteredUser = response.data.filter((item) => item.userRole.key === 'sales' || (item.userRole.key !== 'root' && item.userRole.accessLevel <= 29));
				setListUser(filteredUser);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		server
			.get('/list/customer')
			.then((response) => {
				let filteredCustomer =
					props.store.auth.user.userRole.key === 'root' ? response.data : response.data.filter((item) => item.userId === props.store.auth.user.id && item.status == 1);
				setListCustomer(filteredCustomer);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		server
			.get('/list/order-status')
			.then((response) => {
				setListOrderStatus(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {}, []);

	const getOrder = () => {
		setOrderLoader(true);
		server
			.get('/order/' + params.id)
			.then((response) => {
				setOrder({ ...response.data, ordNo: zeroFill(6, response.data.id) });
				setButtonType({
					kpSzamla: response.data.ordType === 1 ? 'primary' : 'default',
					utalasosSzamla: response.data.ordType === 2 ? 'primary' : 'default',
					szallitolevel: response.data.ordType === 3 ? 'primary' : 'default',
				});

				if (response.data.ordIsWebshopOrder) {
					server
						.get('/list/shoprenter/order-statuses')
						.then((response) => {
							setListOrderWebshopStatus(response.data);
							props.setLoading(false);
							setOrderLoader(false);
						})
						.catch((error) => {
							errorHandler(error);
						});
				} else {
					props.setLoading(false);
					setOrderLoader(false);
				}
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onRequiredTypeChange = ({ requiredMark }) => {
		setRequiredMarkType(requiredMark);
	};

	const handleSubmitButtonClick = () => {
		orderForm.submit();
	};

	const handleOrderFormSubmit = (values) => {
		setLoading({ ...loading, save: true });
		server
			.patch('/order/' + params.id, values)
			.then((response) => {
				setLoading({ ...loading, save: false });
				getOrder();
				notification('success', 'Megrendelés sikeresen frissítve');
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleDeleteOrder = () => {
		confirm({
			title: 'Biztos, hogy törölni szeretné a megrendelést?',
			icon: <ExclamationCircleOutlined />,
			content: order.ordNo,
			okText: 'Törlés',
			cancelText: 'Mégse',
			okButtonProps: { danger: true },
			cancelButtonProps: { danger: true },
			onOk() {
				server
					.delete('/order/' + params.id)
					.then((response) => {
						notification('success', 'Megrendelés sikeresen törölve', order.ordNo + ' - ' + order.customer.companyName);
						history.push('/orders');
					})
					.catch((error) => {
						errorHandler(error);
					});
			},
			onCancel() {},
		});
	};

	const handleTypeButtonClick = (button) => {
		setButtonType({ [button]: 'primary' });
		let orderType;

		switch (button) {
			case 'kpSzamla':
				orderType = 1;
				break;
			case 'utalasosSzamla':
				orderType = 2;
				break;
			case 'szallitolevel':
				orderType = 3;
				break;

			default:
				break;
		}

		orderForm.setFieldsValue({
			ordType: orderType,
		});
		orderForm.submit();
	};

	const handleInvoiceConfirm = () => {
		if (moment(order.ordDate).format('YYY-MM-DD') != moment().format('YYY-MM-DD') && props.store.auth.user.userRole.accessLevel > 29) {
			setAlertModal(true);
		} else {
			confirm({
				title: 'Hoppá! Biztos, hogy helyes a beállított kedvezmény?',
				icon: <PercentageOutlined />,
				content: `${order.ordDiscount || 0}%`,
				okText: 'Igen',
				cancelText: 'Vissza',
				okButtonProps: { danger: true },
				cancelButtonProps: { danger: true },
				onOk() {
					handleInvoiceConfirmFinal();
				},
				onCancel() {},
			});
		}
	};

	const handleInvoiceConfirmFinal = () => {
		setLoading({ billingoInvoice: true });
		confirm({
			title: 'Biztos, hogy számlázni szeretné a megrendelést?',
			icon: <ExclamationCircleOutlined />,
			content: order.ordNo,
			okText: 'Igen',
			cancelText: 'Mégse',
			okButtonProps: { danger: true },
			cancelButtonProps: { danger: true },
			onOk() {
				handleInvoiceButtonClick();
			},
			onCancel() {
				setLoading({ billingoInvoice: false });
			},
		});
	};

	const handleInvoiceStornoConfirm = () => {
		confirm({
			title: 'Biztos, hogy sztornózni szeretné a számlát?',
			icon: <ExclamationCircleOutlined />,
			content: 'A stornózott megrendelést nem lehet újra kiszámlázni, újat kell helyette készíteni.',
			okText: 'Igen',
			cancelText: 'Mégse',
			okButtonProps: { danger: true },
			cancelButtonProps: { danger: true },
			onOk() {
				handleInvoiceStornoButtonClick();
			},
			onCancel() {},
		});
	};

	const handleInvoiceButtonClick = () => {
		setLoading({ billingoInvoice: true });
		setOrderLoader(true);

		let invoiceItems = [];
		let discount = order.ordDiscount;
		let orderType = order.ordType;
		let warehouseId = order.user.userRole.key === 'sales' ? order.user.warehouse.id : 1; // ID: warehouse

		const setInvoiceItems = new Promise((resolve) => {
			let i = 0;
			order.products.map((product) => {
				invoiceItems.push({
					name: product.prdName,
					unit_price: parseInt(discount) > 0 ? product.orderLine.olnNetPrice * ((100 - parseInt(discount)) / 100) : product.orderLine.olnNetPrice,
					unit_price_type: 'net',
					quantity: product.orderLine.olnQty,
					unit: product.orderLine.olnQtyUnit,
					vat: '27%',
				});
				i++;
			});
			if (i == order.products.length) {
				resolve();
			}
		});

		const handleBilling = () => {
			const footNotes = `A környezetvédelmi termékdíj a gyártó által megfizetésre került. A számlán szereplő *-gal jelölt csomagolószerek kiterjesztett gyártói felelősségi díj megfizetése az eladót terheli a termékek és azok csomagolására vonatkozóan. A számlán szereplő **-gal jelölt csomagolószerek kiterjesztett gyártói felelősségi díj megfizetése a vevőt terheli. A számlán szereplő ***-gal jelölt csomagolószerek kiterjesztett gyártói felelősségi díja megfizetésre került.`;
			setInvoiceItems
				.then(() => {
					let documentUrl = orderType === 3 ? '/delivery-notes' : '/documents';
					billingo
						.post(documentUrl, {
							isWebshopOrder: order.ordIsWebshopOrder,
							email: order.customer.email,
							billingoData: {
								partner_id: order.customer.billingoGroupId || order.customer.billingoCustomerId,
								block_id: process.env.REACT_APP_ENVIRONMENT == 'development' ? 93985 : 93392,
								type: 'invoice',
								fulfillment_date: moment().format('YYYY-MM-DD'),
								due_date:
									orderType === 1
										? moment().format('YYYY-MM-DD')
										: moment()
												.add(order.customer.billingDueDays || props.store.system.billingDueDays, 'days')
												.format('YYYY-MM-DD'),
								payment_method: order.ordType === 1 ? 'cash' : 'wire_transfer',
								language: 'hu',
								currency: order.customer.currency.code,
								electronic: true,
								paid: false,
								items: invoiceItems,
								comment:
									order.ordDiscount > 0
										? `Kedvezmény: ${discount}%. ${footNotes} ${
												order.customer.billingoGroupId
													? `(${order.customer.companyName} - ${order.customer.billingCity}, ${order.customer.billingAddress}, Adószám: ${order.customer.taxID})`
													: ''
										  }`
										: `${footNotes} ${
												order.customer.billingoGroupId
													? `(${order.customer.companyName} - ${order.customer.billingCity}, ${order.customer.billingAddress}, Adószám: ${order.customer.taxID})`
													: ''
										  }`,
							},
						})
						.then((response) => {
							server
								.patch('/order/' + params.id, {
									orderStatusId: listOrderStatus.find((status) => status.key === 100).id, // ID: status KEY: status
									billingoInvoiceNumber: response.data.invoice_number,
									billingoInvoiceId: response.data.id,
								})
								.then(() => {
									setLoading({ billingoInvoice: false });
									setOrderLoader(false);
									getOrder();
									notification('success', 'Megrendelés sikeresen számlázva');
								})
								.catch((error) => {
									errorHandler(error);
								});
						})
						.catch((error) => {
							setLoading({ billingoInvoice: false });
							setOrderLoader(false);
							errorHandler(error);
							notification('warning', 'Számlázás sikertelen, próbálja újra (Billingo hiba)');
						});
				})
				.catch((error) => {
					errorHandler(error);
				});
		};

		server
			.post('/worker/order/process', { orderId: order.id, warehouseId, user: props.store.auth.user, listOrderStatus })
			.then((response) => {
				if (response.data == 'successful_transactions') {
					notification('success', 'Tranzakciók sikeresen végrehajtva', `Várakozás a ${orderType === 3 ? 'szállítólevélre' : 'számlára'} (kb. 10 másodperc)`);
					handleBilling();
				}
				if (response.data == 'ready_to_invoice') {
					handleBilling();
				}
				if (response.data == 'already_invoiced') {
					setLoading({ billingoInvoice: false });
					setOrderLoader(false);
					notification('warning', 'Nem számlázható újra', `A megrendelést már korábban kiszámlázták`);
				}
				if (response.data == 'invoicing_disabled') {
					setLoading({ billingoInvoice: false });
					setOrderLoader(false);
					notification('warning', 'Nem számlázható', `A megrendelést nem számlázható újra`);
				}
			})
			.catch((error) => {
				setLoading({ billingoInvoice: false });
				setOrderLoader(false);
				notification('warning', 'Hiba, próbálja újra!');
				errorHandler(error);
			});
	};

	const handleInvoiceDownloadButtonClick = (lineHeight) => {
		setLoading({ downloadInvoice: true });
		let pdfUrl = order.ordType === 3 ? '/delivery-notes/' + order.id + '/' + lineHeight : '/documents/' + order.billingoInvoiceId + '/download/' + order.billingoInvoiceNumber;
		billingo
			.get(pdfUrl)
			.then((response) => {
				server
					.patch('/order/' + params.id, {
						billingoInvoiceFileName: response.data.fileName,
					})
					.then((data) => {
						setTimeout(() => {
							window.open(process.env.REACT_APP_API_URL + '/files/invoices/' + response.data.fileName);
							setLoading({ downloadInvoice: false });
						}, 2000);
					})
					.catch((error) => {
						setLoading({ downloadInvoice: false });
						setOrderLoader(false);
						errorHandler(error);
						notification('warning', 'PDF letöltése sikertelen (Billingo hiba)', 'Próbálja újra a letöltést!');
					});
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleInvoice80DownloadButtonClick = (lineHeight) => {
		setLoading({ downloadInvoice: true });
		let pdfUrl = order.ordType === 3 ? '/delivery-notes/' + order.id + '/' + lineHeight : '/documents/' + order.billingoInvoiceId + '/download/' + order.billingoInvoiceNumber;
		billingo
			.get(pdfUrl)
			.then((response) => {
				server
					.patch('/order/' + params.id, {
						billingoInvoiceFileName: response.data.fileName,
					})
					.then((data) => {
						setTimeout(() => {
							if (order.ordType == 3) {
								window.open(process.env.REACT_APP_API_URL + '/files/invoices/' + response.data.fileName);
							} else {
								window.open(process.env.REACT_APP_API_URL + '/files/invoices/80_' + response.data.fileName);
							}
							setLoading({ downloadInvoice: false });
						}, 2000);
					})
					.catch((error) => {
						setLoading({ downloadInvoice: false });
						setOrderLoader(false);
						errorHandler(error);
						notification('warning', 'PDF letöltése sikertelen (Billingo hiba)', 'Próbálja újra a letöltést!');
					});
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleCancelledInvoiceDownloadButtonClick = () => {
		setLoading({ downloadInvoice: true });
		let pdfUrl = order.ordType === 3 ? '/delivery-notes/' + order.id : '/documents/' + order.cancelledBillingoInvoiceId + '/download/' + order.cancelledBillingoInvoiceNumber;
		billingo
			.get(pdfUrl)
			.then((response) => {
				server
					.patch('/order/' + params.id, {
						cancelledBillingoInvoiceFileName: response.data.fileName,
					})
					.then((data) => {
						setTimeout(() => {
							window.open(process.env.REACT_APP_API_URL + '/files/invoices/' + response.data.fileName);
							setLoading({ downloadInvoice: false });
						}, 2000);
					})
					.catch((error) => {
						setLoading({ downloadInvoice: false });
						setOrderLoader(false);
						errorHandler(error);
						notification('warning', 'PDF letöltése sikertelen (Billingo hiba)', 'Próbálja újra a letöltést!');
					});
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleCancelledInvoice80DownloadButtonClick = () => {
		setLoading({ downloadInvoice: true });
		let pdfUrl = order.ordType === 3 ? '/delivery-notes/' + order.id : '/documents/' + order.cancelledBillingoInvoiceId + '/download/' + order.cancelledBillingoInvoiceNumber;
		billingo
			.get(pdfUrl)
			.then((response) => {
				server
					.patch('/order/' + params.id, {
						cancelledBillingoInvoiceFileName: response.data.fileName,
					})
					.then((data) => {
						setTimeout(() => {
							if (order.ordType == 3) {
								window.open(process.env.REACT_APP_API_URL + '/files/invoices/' + response.data.fileName);
							} else {
								window.open(process.env.REACT_APP_API_URL + '/files/invoices/80_' + response.data.fileName);
							}
							setLoading({ downloadInvoice: false });
						}, 2000);
					})
					.catch((error) => {
						setLoading({ downloadInvoice: false });
						setOrderLoader(false);
						errorHandler(error);
						notification('warning', 'PDF letöltése sikertelen (Billingo hiba)', 'Próbálja újra a letöltést!');
					});
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleInvoiceStornoButtonClick = () => {
		setLoading({ billingoStorno: true });
		setOrderLoader(true);

		let warehouseId = order.user.userRole.key === 'sales' ? order.user.warehouse.id : 1; // ID: warehouse

		const handleBillingoStorno = () => {
			billingo
				.delete('/documents/' + order.billingoInvoiceId + '/' + order.id)
				.then(() => {
					orderForm.setFieldsValue({
						orderStatusId: listOrderStatus.find((status) => status.key === 95).id,
					});
					server
						.patch('/order/' + params.id, {
							orderStatusId: listOrderStatus.find((status) => status.key === 95).id,
						})
						.then((data) => {
							setLoading({ billingoStorno: false });
							setOrderLoader(false);
							getOrder();
							notification('success', 'Megrendelés sikeresen sztornózva');
						})
						.catch((error) => {
							setLoading({ billingo: false });
							setOrderLoader(false);
							errorHandler(error);
						});
				})
				.catch((error) => {
					setLoading({ billingo: false });
					setOrderLoader(false);
					errorHandler(error);
					notification('warning', 'Billingo hiba, próbálja újra!');
				});
		};

		server
			.post('/worker/order/storno', { orderId: order.id, warehouseId, user: props.store.auth.user, listOrderStatus })
			.then((response) => {
				if (response.data == 'successful_transactions') {
					if (order.ordType != 3) {
						notification('success', 'Tranzakciók sikeresen végrehajtva. Várakozás a storno számlára (kb. 10 másodperc)');
						handleBillingoStorno();
					} else {
						// Új
						orderForm.setFieldsValue({
							orderStatusId: listOrderStatus.find((status) => status.key === 95).id,
						});
						server
							.patch('/order/' + params.id, {
								orderStatusId: listOrderStatus.find((status) => status.key === 95).id,
							})
							.then((data) => {
								setLoading({ billingoStorno: false });
								setOrderLoader(false);
								getOrder();
								notification('success', 'Megrendelés sikeresen sztornózva');
							})
							.catch((error) => {
								setLoading({ billingo: false });
								setOrderLoader(false);
								errorHandler(error);
							});
						// Új - vége

						/* Régi eleje
								setLoading({ billingoStorno: false });
								getOrder();
								notification('success', 'Megrendelés sikeresen sztornózva');
						Régi vége */
					}
				}
				if (response.data == 'ready_to_invoice') {
					handleBillingoStorno();
				}
				if (response.data == 'already_invoiced') {
					setLoading({ billingoInvoice: false });
					setOrderLoader(false);
					notification('warning', 'Nem sztornózható újra', `A megrendelést már korábban sztornózták`);
				}
				if (response.data == 'order_not_invoiced') {
					setLoading({ billingoStorno: false });
					setOrderLoader(false);
					getOrder();
					notification('warning', 'Megrendelés nem sztornózható');
				}
			})
			.catch((error) => {
				errorHandler(error);
				setLoading({ billingoStorno: false });
				notification('warning', 'Hiba! Próbálja újra!');
			});
	};

	const handleClearCanvas = () => {
		sigCanvas.current.clear();
	};

	const handleSaveCanvas = () => {
		if (!sigCanvas.current.isEmpty()) {
			setLoading({ ...loading, save: true });
			let file = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
			server
				.patch('/order/save-signature', { orderId: params.id, file })
				.then((response) => {
					setLoading({ ...loading, save: false });
					getOrder();
					notification('success', 'Megrendelés sikeresen frissítve');
				})
				.catch((error) => {
					errorHandler(error);
				});
		} else {
			notification('warning', 'Aláírás mező üres');
		}
	};

	const handleOrderQuote = () => {
		const url = props.store.auth.user.userRole.key === 'sales' ? '/worker/order/quote80' : '/worker/order/quote';
		setLoading({ ...loading, orderQuote: true });
		server
			.post(url, { orderId: order.id })
			.then((response) => {
				setLoading({ ...loading, orderQuote: false });
				if (props.store.auth.user.userRole.key === 'sales') {
					window.open(process.env.REACT_APP_API_URL + '/files/invoices/ajanlat_80_' + zeroFill(6, order.id) + '.pdf');
				} else {
					window.open(process.env.REACT_APP_API_URL + '/files/invoices/ajanlat_' + zeroFill(6, order.id) + '.pdf');
				}
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	return (
		<PageGuard path={pagePath} data={order}>
			<div className={styles.Order}>
				<OrderLoader loading={orderLoader} />
				<Modal
					visible={alertModal}
					title='Dátum hiba'
					footer={[
						<Button key='submit' type='primary' onClick={() => setAlertModal(false)}>
							Vissza
						</Button>,
					]}>
					<p>A megrendelés beállított dátuma nem egyezik a mai dátummal, kérem állítsa be a mai dátumot.</p>
				</Modal>
				<PageHeader
					className={styles.pageHeader}
					onBack={() => window.history.back()}
					title={order.ordNo}
					tags={<Tag color='blue'>{order.orderStatus ? order.orderStatus.name : ''}</Tag>}
					subTitle={pageDetails.subtitle}
					extra={[
						<Button key='button1' type='default' onClick={handleOrderQuote} loading={loading.orderQuote}>
							Ajánlat
						</Button>,
						order.billingoInvoiceId !== null && order.ordType !== 3 && props.store.auth.user.userRole.key !== 'sales' ? (
							<Button key='button2' type='default' onClick={handleInvoice80DownloadButtonClick} loading={loading.downloadInvoice}>
								{'Blokk (80mm) letöltés'}
							</Button>
						) : null,

						order.ordType !== 3 && order.billingoInvoiceId !== null && order.orderStatus.key != 94 && props.store.auth.user.userRole.key !== 'sales' ? (
							<Button key='button3' type='default' onClick={() => handleInvoiceDownloadButtonClick(60)} loading={loading.downloadInvoice}>
								{order.ordType !== 3 ? (order.orderStatus.key == 100 ? 'Számla letöltés' : 'Sztornó számla letöltés') : 'Szállítólevél letöltés'}
							</Button>
						) : null,

						order.ordType === 3 && order.billingoInvoiceId !== null && order.orderStatus.key != 94 && props.store.auth.user.userRole.key !== 'sales' ? (
							<Dropdown overlay={menu}>
								<Button loading={loading.downloadInvoice}>
									<Space>
										{order.ordType !== 3 ? (order.orderStatus.key == 100 ? 'Számla letöltés' : 'Sztornó számla letöltés') : 'Szállítólevél letöltés'}
										<DownOutlined />
									</Space>
								</Button>
							</Dropdown>
						) : null,

						order.cancelledBillingoInvoiceId !== null && order.orderStatus.key == 95 && props.store.auth.user.userRole.key !== 'sales' ? (
							<Button key='button31' type='default' onClick={handleCancelledInvoiceDownloadButtonClick} loading={loading.downloadInvoice}>
								{'Érvénytelenített számla letöltés'}
							</Button>
						) : null,
						order.cancelledBillingoInvoiceId !== null && order.ordType !== 3 && props.store.auth.user.userRole.key !== 'sales' ? (
							<Button key='button21' type='default' onClick={handleCancelledInvoice80DownloadButtonClick} loading={loading.downloadInvoice}>
								{'Érvénytelenített blokk (80mm) letöltés'}
							</Button>
						) : null,
						(order.orderStatus.key == 100 || order.orderStatus.key == 94) && props.store.auth.user.userRole.key !== 'sales' ? (
							<Button key='button4' type='default' onClick={handleInvoiceStornoConfirm} loading={loading.billingoStorno}>
								Sztornó
							</Button>
						) : null,
						order.orderStatus.key <= 90 && order.products.length > 0 && props.store.auth.user.userRole.key !== 'sales' && order.ordType ? (
							<Button
								key='button5'
								type='default'
								onClick={handleInvoiceConfirm}
								loading={loading.billingoInvoice}
								disabled={order.ordType === 3 && order.products.filter((product) => product.prdBillingType !== 1).length > 0 ? true : false}>
								{order.ordType !== 3 ? 'Számlázás' : 'Szállítólevél generálás'}
							</Button>
						) : null,
						<Button key='button6' type='primary' onClick={handleDeleteOrder} danger disabled={order.products.length > 0}>
							Törlés
						</Button>,
						<Button key='button7' type='primary' onClick={handleSubmitButtonClick} loading={loading.save} disabled={order.orderStatus.key === 100 && !order.ordIsWebshopOrder}>
							Mentés
						</Button>,
					]}
				/>
				<div className={styles.pageBody} key={order.id}>
					<Form
						key={order.id}
						form={orderForm}
						layout='vertical'
						initialValues={{
							requiredMark,
							/* ...order, */
						}}
						onValuesChange={onRequiredTypeChange}
						requiredMark={requiredMark}
						onFinish={handleOrderFormSubmit}>
						<Row gutter={[16, 16]}>
							<Col xs={24} lg={12}>
								<Card style={{ height: '100%' }}>
									<Row justify='space-between' gutter={16}>
										{order.ordIsWebshopOrder && (
											<Col xs={24} lg={24}>
												<Form.Item label='Webshop státusz' name='ordWebshopStatusId'>
													<Select
														showSearch
														defaultValue={order.ordWebshopStatusId}
														optionFilterProp='value'
														filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
														{listOrderWebshopStatus.map((webshopStatus) => {
															return (
																<Option key={webshopStatus.id} value={webshopStatus.id}>
																	{webshopStatus.name}
																</Option>
															);
														})}
													</Select>
												</Form.Item>
											</Col>
										)}
										<Col xs={24} lg={4}>
											<Form.Item
												label='Azonosító'
												name=''
												initialValue={order.ordNo}
												rules={[
													{
														required: true,
														message: 'Kötelező mező',
													},
												]}>
												<Input disabled />
											</Form.Item>
										</Col>
										<Col xs={24} lg={10}>
											<Form.Item label='Ügyfél' name='customerId'>
												<Select
													disabled={true}
													showSearch
													defaultValue={order.customerId}
													optionFilterProp='value'
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													{listCustomer.map((customer) => {
														return (
															<Option key={customer.id} value={customer.id}>
																{customer.companyName + ' (' + customer.billingCity + ')'}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</Col>
										<Col xs={24} lg={10}>
											{props.store.auth.user.userRole.accessLevel > 29 ? (
												<Form.Item label='Értékesítő'>
													<Text strong>{order.user.fullName}</Text>
												</Form.Item>
											) : (
												<Form.Item label='Értékesítő' name='userId'>
													<Select
														disabled={order.orderStatus.key === 100}
														showSearch
														defaultValue={order.userId}
														optionFilterProp='value'
														filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
														{listUser.map((user) => {
															return (
																<Option key={user.id} value={user.id}>
																	{user.fullName}
																</Option>
															);
														})}
													</Select>
												</Form.Item>
											)}
										</Col>

										<Col xs={24} lg={7}>
											<Form.Item label='Megrendelés dátuma' name='ordDate'>
												<DatePicker
													disabled={order.orderStatus.key === 100}
													locale={locale}
													defaultValue={typeof order.ordDate === 'undefined' ? moment() : moment(order.ordDate, 'YYYY/MM/DD - HH:mm')}
													style={{ width: '100%' }}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} lg={7}>
											<Form.Item label='Szállítási dátum' name='ordShipDate'>
												<DatePicker
													disabled={order.orderStatus.key === 100}
													locale={locale}
													defaultValue={typeof order.ordShipDate === 'undefined' ? moment() : moment(order.ordShipDate)}
													style={{ width: '100%' }}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} lg={10}>
											<Form.Item label='Fizetési mód'>
												<Text strong>{order.customer.paymentMethod ? order.customer.paymentMethod.name : ''}</Text>
											</Form.Item>
										</Col>
										{!order.ordIsWebshopOrder && (
											<Row
												gutter={16}
												justify='space-between'
												align='middle'
												style={{ width: '100%', backgroundColor: !order.ordType ? 'red' : 'white', borderRadius: '10px' }}
												className='pt-20 pb-20'>
												<Col xs={24} lg={8}>
													<Button
														disabled={
															order.orderStatus.key === 100 ||
															order.orderStatus.key === 95 ||
															(order.customer.paymentMethod && order.customer.paymentMethod.key === 'wire_transfer')
														}
														type={buttonType.kpSzamla}
														size='large'
														name='kpSzamla'
														block
														style={{ height: '60px', fontSize: '16px' }}
														onClick={(e) => handleTypeButtonClick(e.currentTarget.name)}>
														Készpénzes számla
													</Button>
												</Col>
												<Col xs={24} lg={8}>
													<Button
														disabled={
															order.orderStatus.key === 100 || order.orderStatus.key === 95 || (order.customer.paymentMethod && order.customer.paymentMethod.key === 'cash')
														}
														type={buttonType.utalasosSzamla}
														size='large'
														name='utalasosSzamla'
														block
														style={{ height: '60px', fontSize: '16px' }}
														onClick={(e) => handleTypeButtonClick(e.currentTarget.name)}>
														Utalásos számla
													</Button>
												</Col>
												<Col xs={24} lg={8}>
													<Button
														disabled={order.orderStatus.key === 100 || order.orderStatus.key === 95}
														type={buttonType.szallitolevel}
														size='large'
														name='szallitolevel'
														block
														style={{ height: '60px', fontSize: '16px' }}
														onClick={(e) => handleTypeButtonClick(e.currentTarget.name)}>
														Szállítólevél
													</Button>
												</Col>

												<Form.Item name='ordType' hidden></Form.Item>
											</Row>
										)}

										<Col xs={24} lg={24}></Col>
									</Row>
								</Card>
							</Col>
							<Col xs={12} lg={4}>
								<Card style={{ height: '100%' }}>
									{/* 	<Form.Item label='Státusz' name='orderStatusId' initialValue={order.orderStatusId}>
									<Select
										showSearch
										optionFilterProp='value'
										filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
										{listOrderStatus.map((orderStatus) => {
											return (
												<Option key={orderStatus.id} value={orderStatus.id}>
													{orderStatus.name}
												</Option>
											);
										})}
									</Select>
								</Form.Item> */}
									{order.ordIsWebshopOrder ? (
										<Form.Item label='Megjegyzések'>{order.ordDescription}</Form.Item>
									) : (
										<Form.Item label='Vevő aláírása'>
											{order.ordSigned && <img src={props.store.system.filesPath + '/signatures/' + order.id + '.png'} className={styles.signature} />}
										</Form.Item>
									)}
								</Card>
							</Col>
							<Col xs={12} lg={8}>
								<Card className={styles.customerCard}>
									<Title level={3} className={styles.customerText}>
										{order.customer.companyName}
									</Title>
									<Title level={5} className={styles.customerText + ' mt-0'}>
										{order.customer.billingAddress}
									</Title>
									<Title level={5} className={styles.customerText + ' mt-0 mb-30'}>
										{order.customer.billingPostalCode} - {order.customer.billingCity}
									</Title>
									<Text strong className={styles.customerText}>
										Adószám:{' '}
									</Text>
									<Text className={styles.customerText}>
										{order.customer.taxID} {order.customer.vatID ? ` (${order.customer.vatID})` : null}
									</Text>
									<Paragraph className={styles.customerText + ' mb-0 mt-30'}>{order.customer.phone}</Paragraph>
									<Paragraph className={styles.customerText + ' mb-0'}>{order.customer.email}</Paragraph>
								</Card>
							</Col>
						</Row>
					</Form>
					<Row>
						<OrderLines order={order} getOrder={getOrder} store={props.store} loading={loading.billingoInvoice} />
					</Row>
					<Row style={{ marginTop: '16px' }}>
						<OrderTotal order={order} getOrder={getOrder} />
					</Row>
					<Row style={{ marginTop: '16px' }} gutter={[16, 16]}>
						<Col xs={12} lg={0}>
							<Button
								key='button5'
								type='primary'
								onClick={handleDeleteOrder}
								danger
								disabled={order.products.length > 0}
								block
								size='large'
								style={{ height: '60px', fontSize: '24px' }}>
								Törlés
							</Button>
						</Col>
						<Col xs={12} lg={0}>
							<Button
								key='button6'
								type='primary'
								onClick={handleSubmitButtonClick}
								loading={loading.save}
								disabled={order.orderStatus.key === 100 || order.orderStatus.key === 95}
								block
								size='large'
								style={{ height: '60px', fontSize: '24px' }}>
								Mentés
							</Button>
						</Col>
						{order.orderStatus.key < 95 && order.products.length > 0 && order.ordType ? (
							<Col xs={24} lg={0}>
								<Button
									key='button32'
									type='primary'
									size='large'
									onClick={handleInvoiceConfirm}
									loading={loading.billingoInvoice}
									block
									style={{ height: '100px', fontSize: '24px', backgroundColor: 'orange' }}
									disabled={order.ordType === 3 && order.products.filter((product) => product.prdBillingType !== 1).length > 0 ? true : false}>
									{order.ordType !== 3 ? 'Számlázás' : 'Szállítólevél generálás'}
								</Button>
							</Col>
						) : null}
						{order.orderStatus.key == 100 || order.orderStatus.key === 95 ? (
							<>
								<Col xs={24} lg={0}>
									<Button
										key='button11'
										type='primary'
										size='large'
										onClick={() => handleInvoice80DownloadButtonClick(60)}
										block
										style={{ height: '100px', fontSize: '24px' }}
										loading={loading.downloadInvoice}>
										{'Blokk (80mm) letöltés'}
									</Button>
								</Col>
								<Col xs={24} lg={0}>
									<Button
										key='button11'
										size='large'
										onClick={() => handleInvoice80DownloadButtonClick(120)}
										block
										style={{ height: '60px', fontSize: '20px' }}
										loading={loading.downloadInvoice}>
										{'Másodlagos blokk (80mm) letöltés'}
									</Button>
								</Col>
							</>
						) : null}

						{order.orderStatus.key === 95 ? (
							<Col xs={24} lg={0}>
								<Button
									key='button111'
									type='primary'
									size='large'
									onClick={handleCancelledInvoice80DownloadButtonClick}
									block
									style={{ height: '100px', fontSize: '24px' }}
									loading={loading.downloadInvoice}>
									{'Érvénytelenített blokk (80mm) letöltés'}
								</Button>
							</Col>
						) : null}

						{!order.ordSigned && (
							<>
								<Col xs={12} lg={0} className={styles.signatureButton}>
									<Button key='button111' type='primary' danger size='large' onClick={handleClearCanvas} block style={{ height: '100px', fontSize: '24px' }}>
										Aláírás törlése
									</Button>
								</Col>
								<Col xs={12} lg={0} className={styles.signatureButton}>
									<Button key='button111' type='primary' size='large' onClick={handleSaveCanvas} block style={{ height: '100px', fontSize: '24px' }}>
										Aláírás mentése
									</Button>
								</Col>
								<Col xs={24} lg={0}>
									<SignaturePad ref={sigCanvas} canvasProps={{ height: 600, className: styles.signatureCanvas }} />
								</Col>
							</>
						)}
					</Row>
				</div>
			</div>
		</PageGuard>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(Order, pagePath));
